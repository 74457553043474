import 'bootstrap/dist/css/bootstrap.css';
import { navigate } from 'gatsby';
import * as React from 'react';
import './../assets/fontawesome/css/all.css';
import './../templates/CV.css';

export default (): JSX.Element => {
  if (typeof window !== 'undefined') {
    let locale = 'en';
    
     navigate(`/${locale}#biz-card`);
  }

  return (
     <div className="container" style={{ userSelect: 'none' }}>
      <article className="resume-wrapper text-center position-relative">
        <div className="resume-wrapper-inner mx-auto text-left bg-white shadow-lg resume-header">
        <p>--Redirecting.... Can't wait?-- <a href="/">Try here</a></p>
        </div>
        </article>
      </div>
    
  );
};
